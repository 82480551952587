import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { DeliveryProductService } from './delivery-product.service';
import { CountryService } from 'src/helpers/CountryService';
import { EciPrincipalService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme/ui/button';
import DxNumberBox from 'devextreme/ui/number_box';
import { confirm } from 'devextreme/ui/dialog';
import FileUploader from 'devextreme/ui/file_uploader';
import { DataGridUtilService } from '../service/DataGridUtilService';

@Component({
    selector: 'vltc-delivery-product',
    templateUrl: './delivery-product.component.html',
    styleUrls: ['./delivery-product.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryProductComponent implements OnInit {

    @ViewChild('gridContainer') dataGrid: DxDataGridComponent;
    @ViewChild('searchContainer') searchesDataGrid: DxDataGridComponent;
    dataSource: DataSource;
    country: any;
    selectedOperation = 'contains';
    filterValue: any = 'Product updated';
    deliveryTime = 0;
    selectedRowsIds: string[] = [];
    selectedRows: string[] = [];
    selectedArticlesMapByPage = new Map<number, string[]>();
    deselectedArticlesMapByPage = new Map<number, string[]>();
    selectAllEnabled = false;
    isSelectAllEnabled = false;
    isEnabled = false;
    fileName: any[] = [];
    fileToUpload: File = null;
    secondDataSource: DataSource;
    fileUploader: FileUploader;
    searchesDataSource: DataSource;
    isPopupVisible = false;
    isSaveSearchPopupVisible = false;
    isColumnsPopupVisible = false;
    searchName = '';
    searchValue = '';
    gridState = '';
    options: string[];
    selectedColumns: string[];
    roles: string[];

    constructor(
        private deliveryProductService: DeliveryProductService,
        private countryService: CountryService,
        public translateService: EciTenantAwareLanguageService,
        private principalService: EciPrincipalService,
        public dataGridUtilService: DataGridUtilService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c);
        this.options = deliveryProductService.getDeliveryProductColumns();
    }

    ngOnInit(): void {
        const identity = this.principalService.getIdentity();
        if (identity) {
            this.roles = this.principalService.getIdentity().roles;
        }
        this.dataSource = this.deliveryProductService.createDeliveryProductDataSource();
        this.searchesDataSource = this.deliveryProductService.createDeliveryProductSearchesDataSource();
    }

    uploadFile(event: any) {
        const files = event.value;
        if (files) {
            this.fileToUpload = files[0];
            console.log('FileUpload -> files ', this.fileToUpload);
        }
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        console.log('File to upload ' + this.fileToUpload);
    }

    getFileUploader(): FileUploader {
        const fileUploaderElement = document.getElementById('fileUploaderId');
        const fileUploaderInstance = FileUploader.getInstance(fileUploaderElement) as FileUploader;
        return fileUploaderInstance;
    }

    resetFileUploader() {
        this.getFileUploader().reset();
        this.dataSource = this.deliveryProductService.createDeliveryProductDataSource();
    }

    applyFilter(operation, value) {
        this.selectedOperation = operation;
        this.filterValue = value;
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    onNewEntry(event) {
        event.data.active = true;
    }

    onEditorPreparing(event) {
        if (event.parentType === 'headerRow' && event.command === 'select') {
            event.editorElement.remove();
        }

        if (event.parentType === 'filterRow' && event.dataField === 'itemGroupCategory') {
            event.cancel = true;
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'totalGroupCount',
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    width: 136,
                    text: this.isSelectAllEnabled ? this.translateService.get('app.deliveryProduct.toolbar.deselectAll')
                        : this.translateService.get('app.deliveryProduct.toolbar.selectAll'),
                    onClick: this.selectAllArticles.bind(this),
                    elementAttr: {
                        id: 'selectAllButtonId',
                    },
                },

            },
            {
                location: 'after',
                text: this.translateService.get('app.deliveryProduct.toolbar.deliveryDaysForArticles'),
                options: {
                    size: 10,
                    fontSize: 14,
                },
            },
            {
                location: 'after',
                widget: 'dxNumberBox',
                options: {
                    width: 50,
                    displayExpr: 'Days for delivery',
                    valueExpr: 'value',
                    onValueChanged: this.setDeliveryTimeValueFromToolbar.bind(this),
                    value: 0,
                    elementAttr: {
                        id: 'daysForDeliveryInputId',
                    },
                },
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    width: 90,
                    text: this.translateService.get('app.deliveryProduct.toolbar.save'),
                    type: 'success',
                    onClick: this.updateDeliveryTimeForArticles.bind(this),
                },
            });
    }

    updateDeliveryTimeForArticles() {
        if (Array.isArray(this.selectedRows) && this.selectedRows.length > 0) {
            const result = confirm('<i>' +
                this.translateService.get('app.deliveryProduct.confirmationDialog.confirmationMessage') +
                ' ' + this.getTotalRowCount() + ' ' +
                this.translateService.get('app.deliveryProduct.confirmationDialog.articles') + ' ?</i>',
                this.translateService.get('app.deliveryProduct.confirmationDialog.confirmChanges'));
            result.then(dialogResult => {
                if (dialogResult) {
                    this.dataGrid.instance.beginCustomLoading(this.translateService.get('app.deliveryProduct.confirmationDialog.savingArticles'));
                    this.deliveryProductService.updateDeliveryTimeForSelectedArticles(this.convertMapValuesToArray(this.selectedArticlesMapByPage),
                        this.convertMapValuesToArray(this.deselectedArticlesMapByPage), this.isSelectAllEnabled,
                        JSON.stringify(this.dataGrid.instance.getCombinedFilter()),
                        this.deliveryTime != null ? this.deliveryTime.toString() : '', this.country.id, this.fileToUpload, this.roles)
                        .subscribe(response => {
                            let message = response.body + ' ' +
                                this.translateService.get('app.deliveryProduct.confirmationDialog.articlesWereSaved');
                            if (response.body.toString() === '0') {
                                message = this.translateService.get('app.deliveryProduct.confirmationDialog.unSavedBans');
                            }
                            notify(message, 'success', 2000);
                            this.updateElementsAfterUpdate();
                        });
                }
            });
        }
    }

    selectAllArticles(e) {
        if (this.isSelectAllEnabled === false) {
            this.selectedArticlesMapByPage.clear();
            this.deselectedArticlesMapByPage.clear();
            this.selectAllVisibleRows();
            this.isSelectAllEnabled = true;
            e.component.option({
                text: this.translateService.get('app.deliveryProduct.toolbar.deselectAll'),
            });

        } else {
            this.isSelectAllEnabled = false;
            this.selectedArticlesMapByPage.clear();
            this.deselectedArticlesMapByPage.clear();
            this.dataGrid.instance.clearSelection();
            e.component.option({
                text: this.translateService.get('app.deliveryProduct.toolbar.selectAll'),
            });
        }
    }

    getTotalRowCount(): number {
        let totalRows = 0;
        if (this.isSelectAllEnabled) {
            totalRows = this.dataGrid.instance.totalCount() - this.convertMapValuesToArray(this.deselectedArticlesMapByPage).length;
        } else {
            totalRows = this.dataGrid.instance.getSelectedRowKeys().length;
        }
        return totalRows;
    }

    onOptionChanged(e) {
        if (e.fullName === 'paging.pageIndex') {
            console.log('onOptionChanged');
        }
    }

    onContentReady(e) {
        if (this.isSelectAllEnabled === true) {
            const selectedArticlesPerPage = this.selectedArticlesMapByPage.get(this.dataGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.dataGrid.instance.selectRows(selectedArticlesPerPage, true);
            } else {
                this.selectAllVisibleRows();
            }
        }
        if (this.isSelectAllEnabled === false) {
            const selectedArticlesPerPage = this.selectedArticlesMapByPage.get(this.dataGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.dataGrid.instance.selectRows(selectedArticlesPerPage, true).then(r => {
                });
            }
        }
    }

    onSelectionChanged(e: any) {
        this.selectedArticlesMapByPage.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => r.isSelected).map(m => m.key));
        this.deselectedArticlesMapByPage.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => !r.isSelected).map(m => m.key));
    }

    selectAllVisibleRows() {
        const visibleRows = this.dataGrid.instance.getVisibleRows().map(r => r.key);
        this.dataGrid.instance.selectRows(visibleRows, true);
    }

    deselectAllVisibleRows() {
        const visibleRows = this.dataGrid.instance.getVisibleRows().map(r => r.key);
        this.dataGrid.instance.deselectRows(visibleRows);
    }

    filterVisibleRows(selectedArticles: string[], visibleArticles: string[]) {
        const deselectedArticles = visibleArticles.filter(
            function(e) {
                return this.indexOf(e) < 0;
            },
            selectedArticles,
        );
        return deselectedArticles;
    }

    setDeliveryTimeValueFromToolbar(e) {
        this.deliveryTime = e.value;
    }

    convertMapValuesToArray(map: Map<number, string[]>): string[] {
        const result: string[] = [];
        map.forEach((value: string[], key: number) => {
            value.forEach(value1 => result.push(value1));
        });
        return result;
    }

    updateElementsAfterUpdate() {
        this.isSelectAllEnabled = false;
        this.deliveryTime = 0;
        this.selectedRows.length = 0;
        this.selectedArticlesMapByPage.clear();
        this.deselectedArticlesMapByPage.clear();
        this.dataGrid.instance.clearSelection();
        this.dataGrid.instance.refresh();
        this.dataGrid.instance.endCustomLoading();
        // there's no two way binding for toolbar elements
        const buttonElement = document.getElementById('selectAllButtonId');
        DxButton.getInstance(buttonElement).option('text', this.translateService.get('app.deliveryProduct.toolbar.selectAll'));
        const numberBoxElement = document.getElementById('daysForDeliveryInputId');
        DxNumberBox.getInstance(numberBoxElement).option('value', 0);
    }

    uploadFileToServer() {
        try {
            this.dataSource = null;
            const dts = this.deliveryProductService.createDataSource(this.fileToUpload, this.country.id);
            this.dataSource = dts;
            this.dataGrid.instance.endCustomLoading();
            this.dataGrid.instance.repaint();

        } catch (e) {
            console.error('Error in setting data source ' + e);
        }
    }

    saveNewSearch() {

        const filterValue = this.dataGrid.instance.getCombinedFilter() !== 'undefined' ? this.dataGrid.instance.getCombinedFilter() : '';
        const gridState = this.dataGrid.instance.state();
        const searchName = this.searchName;

        if (searchName === ' ' || filterValue === ' ') {
            alert(this.translateService.get('app.deliveryProduct.search.warning'));
        } else {
            this.principalService.getIdentityAsync().then(r => {
                this.deliveryProductService.saveDeliveryProductSearch(searchName, filterValue, gridState, r.login).subscribe(response => {
                    notify(this.translateService.get('app.deliveryProduct.confirmationDialog.searchSaved'), 'success', 1000);
                    this.searchesDataGrid.instance.refresh();
                });
                return r.login;
            }).catch(error => Promise.reject(error));
            this.isSaveSearchPopupVisible = false;
        }
    }

    cancelSaveSearch() {
        this.isSaveSearchPopupVisible = false;
        this.searchName = '';
        this.searchValue = '';
        this.gridState = '';
    }

    setProductsGridState(e) {
        this.dataGrid.instance.state(JSON.parse(e.data.gridState));
        this.dataGrid.instance.refresh();
        this.isPopupVisible = false;
    }

    clearGridFilter() {
        this.dataGrid.instance.clearFilter();
        const columns = this.dataGrid.instance.getVisibleColumns();
        for (const column of columns) {
            this.dataGrid.instance.columnOption(column.dataField, 'filterValues', null);
            this.dataGrid.instance.columnOption(column.dataField, 'filterValue', null);
            this.dataGrid.instance.columnOption(column.dataField, 'filterType', null);
            this.dataGrid.instance.columnOption(column.dataField, 'selectedFilterOperation', null);
        }
    }

    showSearches() {
        console.log('isPopupVisible is ' + this.isPopupVisible);
        this.isPopupVisible = true;
    }

    showColumnsSelection() {
        console.log('isPopupVisible is ' + this.isColumnsPopupVisible);
        this.isColumnsPopupVisible = true;
    }

    showNewSearchForm() {
        this.searchValue = this.dataGrid.instance.getCombinedFilter() !== 'undefined' ? this.dataGrid.instance.getCombinedFilter() : '';
        this.gridState = this.dataGrid.instance.state();
        this.isSaveSearchPopupVisible = true;

    }

    downloadCsv() {
        if (typeof this.selectedColumns !== 'undefined') {
            if (this.selectedColumns.length !== 0) {
                console.log('isPopupVisible is ' + this.isColumnsPopupVisible);
                this.isColumnsPopupVisible = false;
                this.deliveryProductService.downloadCsv(this.country.id,
                    typeof this.dataGrid.instance.getCombinedFilter() !== 'undefined' ? JSON.stringify(this.dataGrid.instance.getCombinedFilter()) : '[]',
                    this.getSelectedColumns(), this.fileToUpload);
            }
        }
    }

    getSelectedColumns() {
        return this.selectedColumns;
    }

    updateSelectedColumns(event) {
        this.selectedColumns = event.value;
    }
}
