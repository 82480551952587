import { Component, OnInit } from '@angular/core';
import { Account, EciPrincipalService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';
import { HomeService, TimeSeries, TimeSeriesResponse } from './home.service';
import { CountryManagementService } from '../master-data/components/country-management/country-management.service';
import { Observable } from "rxjs";

/**
 * A simple app-specific component.
 */
@Component({
    selector: 'vltc-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

    account: Account;
    country: string;
    countryId: string;
    timeframes: string[];
    selectedTimeframe: string;
    timeSeriesResponse: TimeSeriesResponse;
    timeSeriesArray: TimeSeries[];
    gaugeValue: number;
    maxGaugeValue: number;
    minGaugeValue: number;
    totalCalculationLogs: number;
    newCalculationLogs: number;
    standardDeliveryDays: number;
    masterArticleJobs: number;
    running = 0;
    pending = 0;
    global = 0;
    individual = 0;

    constructor(
        private principalService: EciPrincipalService,
        private countryService: CountryService,
        private countryManagementService: CountryManagementService,
        private homeService: HomeService,
    ) {
        this.countryService.getCountry.subscribe(c => {
            this.country = c.name;
            this.countryId = c.id;
        });
        this.timeframes = [
            '1H',
            '6H',
            '12H',
            '1D',
            '1W',
            '4W',
            '6W',
        ];
        this.selectedTimeframe = this.timeframes[0];
    }

    ngOnInit() {
        this.account = this.principalService.getIdentity();
        const timeSeries = this.homeService.getTimeSeries(this.countryId, this.selectedTimeframe);
        if (timeSeries) {
            timeSeries.subscribe(response => {
                this.timeSeriesResponse = response;
                this.timeSeriesArray = this.timeSeriesResponse.timeSeries;
                this.gaugeValue = this.timeSeriesResponse.averageCalculationTime;
                this.totalCalculationLogs = this.timeSeriesResponse.totalCalculationLogs;
            });
        }
        const calculationLogs = this.homeService.getNewCalculationLogs(this.countryId);
        if (calculationLogs) {
            calculationLogs.subscribe(response => {
                this.newCalculationLogs = response;
            });
        }
        const country = this.countryManagementService.getCountry();
        if (country) {
            country.subscribe(country => this.standardDeliveryDays = country.standardDeliveryDays);
        }
        const vltJobs = this.homeService.getVltJobs(this.countryId);
        if (vltJobs) {
            vltJobs.subscribe(vltJobs => {
                vltJobs.forEach(vltJob => {
                    if (vltJob.enabled === false) {
                        this.pending++;
                    }
                    if (vltJob.enabled === true) {
                        this.running++;
                    }
                });
            });
        }
        const masterArticleJobs = this.homeService.getMasterArticleJobs(this.countryId);
        if (masterArticleJobs) {
            masterArticleJobs.subscribe(masterArticleJobs => {
                this.masterArticleJobs = masterArticleJobs.length;
            });
        }
        const numberOfNotMaintained = this.homeService.getNumberOfMaintained(this.countryId, false);
        if (numberOfNotMaintained) {
            numberOfNotMaintained.subscribe(global => {
                this.global = global;
            });
        }
        const numberOfMaintained = this.homeService.getNumberOfMaintained(this.countryId, true);
        if (numberOfMaintained) {
            numberOfMaintained.subscribe(individual => {
                this.individual = individual;
            });
        }
    }

    isAuthenticated(): boolean {
        return this.principalService.isAuthenticated();
    }

    onValueChanged($event) {
        this.homeService.getTimeSeries(this.countryId, $event.value).subscribe(response => {
            this.timeSeriesResponse = response;
            this.timeSeriesArray = this.timeSeriesResponse.timeSeries;
            this.gaugeValue = this.timeSeriesResponse.averageCalculationTime;
            this.maxGaugeValue = this.timeSeriesResponse.maxCalculationTime;
            this.minGaugeValue = this.timeSeriesResponse.minCalculationTime;
            this.totalCalculationLogs = this.timeSeriesResponse.totalCalculationLogs;
        });
    }
}
